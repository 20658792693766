import * as Yup from 'yup';

export default (t: any) => [
    // Step 1
    Yup.object().shape({
        name: Yup.string().required(t('required')),
        description: Yup.string(),
        config: Yup.object().shape({
            cache_folder: Yup.string().required(t('required'))
        })
    }),
    // Step 2
    Yup.object().shape({
        config: Yup.object().shape({
            lat_n: Yup.number()
                .min(-90, t('Bwtween -90 and 90'))
                .max(90, t('Bwtween -90 and 90'))
                .required(t('required'))
                .label(t('Latitude')),
            long_e: Yup.number()
                .min(-180, t('Bwtween -180 and 180'))
                .max(180, t('Bwtween -180 and 180'))
                .required(t('required'))
                .label(t('Longitude')),
            alt_u: Yup.number().required(t('required')).label(t('Altitude'))
        })
    }),
    // Step 3
    Yup.object().shape({
        spec: Yup.object().shape({
            os: Yup.string().required().label(t('Operating System')),
            cpu: Yup.string().min(5, t('At least 5 characters')).label(t('CPU'))
        })
    }),
    // Step 4
    Yup.object().shape({})
];
