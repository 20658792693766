import { WebsocketSubscription } from 'api/subscription-signal';
import { useAuth } from 'auth/AuthContext';
import { useSubscriptionState } from 'contexts/subscription/SubscriptionContext';
import { User } from 'interfaces/auth.interface';
import React, { createContext, useEffect, useState } from 'react';

export type NodeNotificationState = {
    isSubscribed: boolean;
    subscribedEventTargets: WebsocketSubscription[];
};

const NodeNotificationContext = createContext<NodeNotificationState>({
    isSubscribed: false,
    subscribedEventTargets: []
});

const getSubscrptionTargets = (user: User | null) => {
    if (!user || !user.notification_settings) {
        return [];
    }
    const targets: WebsocketSubscription[] = [];
    if (user.notification_settings.enable_event_notification) {
        user.notification_settings.event_notification_targets.forEach((target) => {
            user.node_permissions.forEach((permission) => {
                if (permission.role === 'admin' || permission.role === 'operator') {
                    targets.push({
                        channel_type: 'node_event',
                        node_id: permission.node.id,
                        sub_channel: target
                    });
                }
            });
        });
    }
    return targets;
};

export const NodeNotificationContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [subscribedEventTargets, setSubscribedEventTargets] = useState<WebsocketSubscription[]>([]);
    const { socket, isConnected } = useSubscriptionState();
    const { user } = useAuth();

    useEffect(() => {
        if (isConnected) {
            const subscriptionList = getSubscrptionTargets(user);
            subscriptionList.forEach((sub) => {
                socket.emit('subscribe', sub);
            });
            setSubscribedEventTargets(subscriptionList);
            setIsSubscribed(true);
        }
        return () => {
            subscribedEventTargets.forEach((sub) => {
                socket.emit('unsubscribe', sub);
            });
            setIsSubscribed(false);
        };
    }, [isConnected]);

    return (
        <NodeNotificationContext.Provider value={{ isSubscribed, subscribedEventTargets }}>
            {children}
        </NodeNotificationContext.Provider>
    );
};

export const useNodeNotificationState = () => React.useContext(NodeNotificationContext);
