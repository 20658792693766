import { alpha, Step, StepContent, StepLabel, Stepper, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledStepper = styled(Stepper)(({ theme }) => ({
    '& .MuiStepConnector-lineVertical': {
        borderColor: alpha('#ffffff', 0.4)
    },
    '& .MuiStepContent-root': {
        borderColor: alpha('#ffffff', 0.4)
    }
}));

export interface Steps {
    label: string;
    description: string;
}

export interface CreationStepperProps {
    steps: Steps[];
    activeStep: number;
    setActiveStep?: (step: number) => void;
}

export default function CreationStepper({ steps, activeStep, setActiveStep }: CreationStepperProps) {
    const { t } = useTranslation();
    return (
        <StyledStepper activeStep={activeStep} orientation="vertical">
            {steps.map(({ label, description }) => (
                <Step key={label}>
                    <StepLabel>{t(label)}</StepLabel>
                    <StepContent>{t(description)}</StepContent>
                </Step>
            ))}
        </StyledStepper>
    );
}
