import React, { createContext, useEffect } from 'react';

export const APP_CONTEXT_DEBUG_KEY = 'app-context-debug';

type AppState = {
    isLoading: boolean;
    isDebug: boolean;
};

const AppContextReducer = (state: AppState, action: any) => {
    switch (action.type) {
        case 'SET_LOADING':
            return {
                ...state,
                isLoading: action.payload
            };
        case 'SET_DEBUG':
            console.log('Debugging mode enabled');
            localStorage.setItem(APP_CONTEXT_DEBUG_KEY, action.payload);
            return {
                ...state,
                isDebug: action.payload
            };
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
};

const initialState = {
    isLoading: false,
    isDebug: localStorage.getItem(APP_CONTEXT_DEBUG_KEY) === 'true'
};

const AppContext = createContext<{ state: AppState; dispatch: React.Dispatch<any> }>({
    state: initialState,
    dispatch: () => {}
});

export const AppContextProvider = ({ children }: any) => {
    const [state, dispatch] = React.useReducer(AppContextReducer, initialState);

    useEffect(() => {
        const isDebug = localStorage.getItem(APP_CONTEXT_DEBUG_KEY) === 'true';
        dispatch({ type: 'SET_DEBUG', payload: isDebug });
    }, []);
    return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export const useAppState = () => React.useContext(AppContext);
