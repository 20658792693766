import { lazy } from 'react';

// project imports
import { AuthorizationRoute } from './AuthorizedRoute';
import { authRoles } from 'auth';
import Loadable from 'ui-component/Loadable';
import NodeCreatePage from 'views/nodes/node-create-page';
import SingleMeteoroidsDashboard from 'views/meteoroids/single-node';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// nodes routing
const Nodes = Loadable(lazy(() => import('views/nodes')));
const NodePage = Loadable(lazy(() => import('views/nodes/node-page')));
const NewCamera = Loadable(lazy(() => import('views/nodes/node-page/dashboard/camera-create-page')));

// users routing
const Users = Loadable(lazy(() => import('views/user-lists')));
const Invitations = Loadable(lazy(() => import('views/invite-links')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const AboutUs = Loadable(lazy(() => import('views/about-us')));

// lists routing
const Lists = Loadable(lazy(() => import('views/user-lists')));

// userprofile routing
const UserProfile = Loadable(lazy(() => import('views/user-profile')));

// profile settings
const Settings = Loadable(lazy(() => import('views/setting')));

// ==============================|| MAIN ROUTING ||============================== //
const MainRoutes = {
    path: '/',
    element: <AuthorizationRoute roles={authRoles.user} />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'nodes',
            element: <Nodes />
        },
        {
            path: 'nodes/create',
            element: <NodeCreatePage />
        },
        {
            path: 'nodes/:id',
            children: [
                {
                    path: '',
                    element: <NodePage />
                },
                {
                    path: 'new-camera',
                    element: <NewCamera />
                }
            ]
        },
        {
            path: 'users',
            element: <Users />
        },
        {
            path: 'users/:id',
            element: <UserProfile />
        },
        {
            path: 'invitations',
            element: <Invitations />
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'user-profile',
            element: <UserProfile />
        },
        {
            path: 'user-setting',
            element: <Settings />
        },
        {
            path: 'Lists',
            element: <Lists />
        },
        {
            path: 'user-profile',
            element: <UserProfile />
        },
        {
            path: 'user-setting',
            element: <Settings />
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'about-us',
            element: <AboutUs />
        }
    ]
};

export default MainRoutes;
