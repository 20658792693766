import { Grid, MenuItem } from '@mui/material';
import { Field } from 'formik';
import { Select, TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';

export default function SpecStep() {
    const { t } = useTranslation();
    return (
        <Grid container spacing={4}>
            <Grid item xs={3}>
                <Field
                    component={Select}
                    type="text"
                    name="spec.os"
                    label={t('Operating System')}
                    formHelperText={{ children: t('Select a supported OS') }}
                >
                    <MenuItem value="Windows 10">Windows 10</MenuItem>
                    <MenuItem value="Ubuntu 16.04">Ubuntu 16.04</MenuItem>
                </Field>
            </Grid>
            <Grid item xs={9} />
            <Grid item xs={5}>
                <Field
                    component={TextField}
                    type="text"
                    name="spec.cpu"
                    label={t('CPU Model')}
                    helperText={t('This is just for referencing Like')}
                />
            </Grid>
        </Grid>
    );
}
