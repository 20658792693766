import { Box, Button, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { NodeInfo } from 'interfaces/api.interface';
import CheckIcon from '@mui/icons-material/Check';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import KeyIcon from '@mui/icons-material/Key';
import ReportIcon from '@mui/icons-material/Report';
import { getNodeToken } from 'api/node';
import { saveAs } from 'file-saver';

interface ResultStepProps {
    isCreating: boolean;
    error: string | null;
    result: NodeInfo | null;
}

export default function ResultStep({ isCreating, error, result }: ResultStepProps) {
    const isDone = !isCreating && result;
    const handleGetToken = async () => {
        if (result) {
            const token = await getNodeToken(result.id);
            const config = {
                NODE_ID: result.id,
                NODE_TOKEN: token.token,
                API_SERVER: process.env.REACT_APP_API_BASE_URL?.replace('api/v1', '')
            };
            saveAs(
                new Blob([JSON.stringify(config)], { type: 'text/plain;charset=utf-8' }),
                `${result.id}-config.json`
            );
        }
    };
    if (error === null) {
        return (
            <Stack alignItems="center" height="100%" justifyContent="center" spacing={2}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {isCreating ? <CircularProgress size={18} /> : <CheckIcon color="success" />}
                    <Typography sx={{ ml: 1 }}>{isCreating ? 'Creating Node' : 'Created Successfully'}</Typography>
                </Box>
                {isDone && (
                    <>
                        <Typography>Node Code: {result?.code} </Typography>
                        <Typography>Create Time: {new Date(result?.create_time + 'Z').toLocaleString()}</Typography>
                        <Divider sx={{ width: '60%', pt: 4 }} />
                        <Stack direction="row" spacing={2}>
                            <Button variant="outlined" startIcon={<AttachFileIcon />}>
                                Software
                            </Button>
                            <Button variant="outlined" startIcon={<KeyIcon />} onClick={handleGetToken}>
                                Get Config
                            </Button>
                        </Stack>
                    </>
                )}
            </Stack>
        );
    } else {
        return (
            <Stack alignItems="center" height="100%" justifyContent="center" spacing={2}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ReportIcon color="error" />
                    <Typography color="error" sx={{ ml: 1 }}>
                        Error: {error}
                    </Typography>
                </Box>
            </Stack>
        );
    }
}
