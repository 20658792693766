import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { NodeCreationForm } from './model';
import { useTranslation } from 'react-i18next';

interface ReviewStepProps {
    values: NodeCreationForm;
}

const ValueText = styled(Typography)(({ theme }) => ({
    fontSize: '0.9rem'
}));

export default function ReviewStep({ values }: ReviewStepProps) {
    const { t } = useTranslation();
    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Typography variant="h5">{t('Summary')}</Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography> {t('node_code')} </Typography>
                <ValueText variant="caption"> {t('Will know after creation')} </ValueText>
            </Grid>
            <Grid item xs={3}>
                <Typography> {t('Node Name')} </Typography>
                <ValueText variant="caption"> {values.name} </ValueText>
            </Grid>
            <Grid item xs={3}>
                <Typography> {t('Node Version')} </Typography>
                <ValueText variant="caption"> {values.version} </ValueText>
            </Grid>
            <Grid item xs={3}>
                <Typography> {t('Node Description')} </Typography>
                <ValueText variant="caption"> {values.description} </ValueText>
            </Grid>
            <Grid item xs={3}>
                <Typography> {t('Location')}: </Typography>
                <ValueText variant="caption"> {`(${values.config.lat_n}, ${values.config.long_e})`} </ValueText>
            </Grid>
            <Grid item xs={3}>
                <Typography> {t('Altitude_')}: </Typography>
                <ValueText variant="caption"> {values.config.alt_u}M </ValueText>
            </Grid>
            <Grid item xs={4}>
                <Typography> {t('Timezone')}: </Typography>
                <ValueText variant="caption"> {values.config.timezone} </ValueText>
            </Grid>
            <Grid item xs={3}>
                <Typography> {t('Operating System')}: </Typography>
                <ValueText variant="caption"> {values.spec.os} </ValueText>
            </Grid>
            <Grid item xs={3}>
                <Typography> {t('CPU Model')}: </Typography>
                <ValueText variant="caption"> {values.spec.cpu} </ValueText>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={3}>
                <Typography> {t('Owner')} </Typography>
                <ValueText variant="caption"> {values.owner.username} </ValueText>
            </Grid>
        </Grid>
    );
}
