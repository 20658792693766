import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { AuthProvider } from 'auth/AuthContext';
import { AppContextProvider } from 'contexts/AppContext';

// utils
import { SnackbarUtilsConfigurator } from 'utils/SnackbarUtils';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const App = () => {
    const customization = useSelector((state: any) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <CssBaseline enableColorScheme />
                    <SnackbarProvider maxSnack={3}>
                        <SnackbarUtilsConfigurator />
                        <ConfirmProvider
                            defaultOptions={{
                                confirmationButtonProps: { autoFocus: true },
                                titleProps: { sx: { fontSize: '1.2rem' } }
                            }}
                        >
                            <NavigationScroll>
                                <AppContextProvider>
                                    <AuthProvider>
                                        <Routes />
                                    </AuthProvider>
                                </AppContextProvider>
                            </NavigationScroll>
                        </ConfirmProvider>
                    </SnackbarProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
