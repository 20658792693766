import { Stack } from '@mui/material';
import { NodeEvenSignal } from 'api/subscription-signal';

const renderDescription = (description: string, showData: boolean) => {
    return (data: NodeEvenSignal) => {
        return (
            <Stack>
                <span>{description}</span>
                <span>node: {data.node_code}</span>
                {showData &&
                    Object.keys(data.data).map((key) => (
                        <span key={key}>
                            {key}: {data.data[key]}
                        </span>
                    ))}
            </Stack>
        );
    };
};

export const NotificationDefination: {
    [key: string]: {
        variant?: 'info' | 'success' | 'warning' | 'error';
        render_description: (data: NodeEvenSignal) => JSX.Element;
    };
} = {
    'node.connected': {
        variant: 'info',
        render_description: renderDescription('Node connected', false)
    },
    'node.node_configured': {
        variant: 'info',
        render_description: renderDescription('Configuration dispatched', true)
    },
    'node.disconnected': {
        variant: 'warning',
        render_description: renderDescription('Node disconnected', false)
    },
    'meteoroid.meteoroid_detected': {
        variant: 'success',
        render_description: renderDescription('Meteoroid detected', true)
    }
};
