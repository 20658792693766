import React, { createContext, useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import SnackbarUtils from 'utils/SnackbarUtils';
import { useAuth } from 'auth/AuthContext';
import { useAppState } from '../AppContext';

const socket = io(process.env.REACT_APP_WS_BASE_URL as any, {
    autoConnect: false,
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: 3,
    auth: {
        token: ''
    },
    transports: ['websocket']
});

export interface SubscriptionState {
    isConnected: boolean;
    socket: Socket;
}

const SubscriptionContext = createContext<SubscriptionState>({
    isConnected: false,
    socket: socket
});

export const SubscriptionContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [isConnected, setIsConnected] = useState(socket.connected);
    const {
        state: { isDebug }
    } = useAppState();

    const { token } = useAuth();

    const connect = () => {
        if (token) {
            socket.auth['token'] = token;
            socket.connect();
            console.log('Connecting to subscription websocket');
        } else {
            console.error('Cannot connect to subscription websocket without a token');
        }
    };

    const onConnect = () => {
        console.log('Subscription websocket connected');
        setIsConnected(true);
    };

    const onDisconnect = () => {
        console.log('Subscription websocket disconnected');
        setIsConnected(false);
    };

    const onError = (error: any) => {
        console.error(error);
        SnackbarUtils.error(error);
        setIsConnected(false);
    };

    useEffect(() => {
        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);

        socket.on('connect_error', onError);
        connect();
        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('connect_error');
            console.log('Disconnecting from subscription websocket');
            socket.disconnect();
        };
    }, [token]);
    return <SubscriptionContext.Provider value={{ socket, isConnected }}>{children}</SubscriptionContext.Provider>;
};

export const useSubscriptionState = () => React.useContext(SubscriptionContext);
