import { useNodeNotificationState } from 'contexts/subscription/NodeNotificationContext';
import { useSubscriptionState } from 'contexts/subscription/SubscriptionContext';
import { useEffect } from 'react';
import { NodeEvenSignal } from 'api/subscription-signal';
import { useSnackbar } from 'notistack';
import { NotificationDefination } from './NotificationDefination';

const BackgroundNotification = () => {
    const { isSubscribed } = useNodeNotificationState();
    const { socket } = useSubscriptionState();
    const useSnackbarRef = useSnackbar();

    const handleNodeNotification = async (event: NodeEvenSignal) => {
        const info = NotificationDefination[`${event.type}.${event.name}`];
        if (info) {
            useSnackbarRef.enqueueSnackbar(info.render_description(event), {
                variant: info.variant,
                autoHideDuration: 2000
            });
        } else {
            useSnackbarRef.enqueueSnackbar(`Unknown event: ${event.type} ${event.name}`, {
                variant: 'warning',
                autoHideDuration: 2000
            });
        }
    };
    useEffect(() => {
        if (isSubscribed) {
            socket.on('node_event', handleNodeNotification);
        }
        return () => {
            socket.off('node_event', handleNodeNotification);
        };
    }, [isSubscribed]);
};

export default BackgroundNotification;
