// assets
import { IconDashboard, IconLink } from '@tabler/icons';
import Twitter from '@mui/icons-material/Twitter';
// constant
const icons = { IconDashboard, IconLink };
import { useAuth } from 'auth/AuthContext';

const List = (t: any) => {
    const { user } = useAuth();
    return {
        id: 'Lists',
        title: t('user_list'),
        caption: t('user_list'),
        type: 'group',
        resource: 'user',
        display_for: ['admin'],
        children: [
            {
                id: 'Lists',
                title: t('user_list'),
                type: 'item',
                url: '/users',
                icon: icons.IconDashboard,
                breadcrumbs: true
            },
            {
                id: 'invitations',
                title: t('Invitations'),
                type: 'item',
                url: '/invitations',
                icon: icons.IconLink,
                breadcrumbs: true
            },
            {
                id: 'user',
                title: t('user profile'),
                type: 'item',
                url: `/users/${user?.id}`,
                icon: Twitter,
                breadcrumbs: true
            }
        ]
    };
};

export default List;
