// material-ui
import { useTheme } from '@mui/material/styles';

import logo from 'assets/images/logo.jpg';

const Logo = () => {
    const theme = useTheme();

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <img src={logo} alt="Meteoroid.fit" width="40" />
            <span
                style={{
                    marginLeft: '4px',
                    fontSize: '1.1rem',
                    fontWeight: 'bold',
                    color: theme.palette.text.primary
                }}
            >
                METEOROID.FIT
            </span>
        </div>
    );
};

export default Logo;
