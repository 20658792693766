// assets
import { IconKey } from '@tabler/icons';

// constant
const icons = {
    IconKey
};

const debugPages = (t) => {
    return {
        id: 'debug',
        title: 'Debug Pages',
        caption: 'Debug Pages', // optional
        type: 'group', // 'item', 'collapse', 'group', 'external-link'
        debug: true,
        resource: 'user',
        display_for: ['admin'],
        children: [
            {
                id: 'authentication',
                title: 'Authentication',
                type: 'collapse',
                icon: icons.IconKey,
                children: [
                    {
                        id: 'login',
                        title: 'Login',
                        type: 'item',
                        url: '/login',
                        target: true
                    },
                    {
                        id: 'register',
                        title: 'Register',
                        type: 'item',
                        url: 'register',
                        target: true
                    }
                ]
            }
        ]
    };
};

export default debugPages;
