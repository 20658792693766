// assets
//import { IconCircles } from '@tabler/icons';
import LanOutlined from '@mui/icons-material/LanOutlined';
import { User } from 'interfaces/auth.interface';
import { Children } from 'react';
import { Trans } from 'react-i18next';
// constant
const icons = {
    LanOutlined
};

const nodes = (t: any) => {
    return {
        id: 'nodes',
        title: t('nodes'),
        caption: t('nodes-management'),
        type: 'group',
        children: [
            {
                id: 'nodes',
                title: t('nodes'),
                type: 'collapse',
                url: '/nodes',
                icon: icons.LanOutlined,
                breadcrumbs: true,
                childrenRender: (user: User) => {
                    return user.node_permissions.map((node) => {
                        // display nodes depending on the role
                        if (node.role == 'admin' || node.role == 'data_viewer')
                            return {
                                id: node.node.id,
                                title: `${node.node.name} (${node.node.code})`,
                                type: 'item',
                                url: `/nodes/${node.node.id}`,
                                breadcrumbs: true
                            };
                        else return null;
                    });
                }
            }
        ]
    };
};

export default nodes;
