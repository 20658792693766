import { Navigate } from 'react-router-dom';
import MainLayout from 'layout/MainLayout';
import { useAuth } from 'auth/AuthContext';
import { ReactNode } from 'react';

type AuthorizedRouteProps = {
    roles: string[];
};

export const AuthorizationRoute = (props: AuthorizedRouteProps) => {
    const { user, token } = useAuth();

    if (!user || !token) {
        return <Navigate to="/login" />;
    } else {
        if (props.roles.includes('admin')) {
            return <MainLayout />;
        }
        return <Navigate to="/" />;
    }
};
