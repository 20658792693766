import { AutocompleteRenderInputParams, Grid } from '@mui/material';
import MuiTextField from '@mui/material/TextField';
import { Field } from 'formik';
import { Autocomplete, TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';

const timezoneOptions = (Intl as any).supportedValuesOf('timeZone');

export default function LocationStep() {
    const { t } = useTranslation();
    return (
        <Grid container spacing={2} rowSpacing={4}>
            <Grid item xs={3}>
                <Field
                    component={TextField}
                    type="number"
                    name="config.lat_n"
                    label={t('Latitude')}
                    helperText={t('Latitude in float Positive is north negative is south')}
                />
            </Grid>
            <Grid item xs={3}>
                <Field
                    component={TextField}
                    type="number"
                    name="config.long_e"
                    label={t('Longitude')}
                    helperText={t('Longitude in float Positive is east negative is west')}
                />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={3}>
                <Field
                    component={TextField}
                    type="number"
                    name="config.alt_u"
                    label={t('Altitude')}
                    helperText={t('Altitude in float')}
                />
            </Grid>
            <Grid item xs={9} />
            <Grid item xs={4}>
                <Field
                    component={Autocomplete}
                    name="config.timezone"
                    label={t('Timezone')}
                    options={timezoneOptions}
                    disableClearable
                    renderInput={(params: AutocompleteRenderInputParams) => (
                        <MuiTextField
                            {...params}
                            name="timezone"
                            label={t('Timezone')}
                            variant="outlined"
                            helperText={t('Timezone in IANA format')}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
}
