import dashboard from './dashboard';
import nodes from './nodes';
import debugPages from './debugPages';
import utilities from './utilities';
import other from './other';
import List from './lists';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, nodes, List, debugPages, utilities, other]
};

export default menuItems;
