import axios, { AxiosRequestConfig } from 'axios';
import SnackbarUtils from 'utils/SnackbarUtils';

import { AUTH_TOKEN_KEY, AUTH_USER_KEY } from './auth/AuthContext';

axios.defaults.timeout = 60000;

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY);
    const authHeaders = token ? { Authorization: `Bearer ${token}` } : {};
    return {
        ...config,
        headers: {
            ...config.headers,
            ...authHeaders
        }
    };
});

axios.interceptors.response.use(
    (config) => config,
    (error) => {
        if (error.code && error.code === 'ECONNABORTED') {
            SnackbarUtils.error('Request timed out');
        } else if (error.response) {
            const { status, data } = error.response;
            // if is not login
            if (status === 401) {
                if (!data?.message.includes('Wrong password')) {
                    localStorage.removeItem(AUTH_TOKEN_KEY);
                    localStorage.removeItem(AUTH_USER_KEY);
                    window.location.reload();
                }
            } else if (status === 403) {
                SnackbarUtils.error('You are not authorized to perform this action');
            } else if (status === 500) {
                SnackbarUtils.error('Internal server error');
            } else if (status === 404) {
                SnackbarUtils.error('Resource not found');
            } else if (status === 406) {
                SnackbarUtils.error('Action not allowed: ' + data?.message || 'Unkown error');
            }
        }
        return Promise.reject(error);
    }
);

export default axios;
