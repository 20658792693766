import { Button, Grid, Stack } from '@mui/material';
import { createNode } from 'api/node';
import { useAuth } from 'auth/AuthContext';
import { Form, Formik } from 'formik';
import { NodeInfo } from 'interfaces/api.interface';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import NodeCreationStepper from '../../../ui-component/forms/CreationStepper';
import BasicStep from './BasicStep';
import ResultStep from './ResultStep';
import LocationStep from './LocationStep';
import { NodeCreationForm } from './model';
import PermissionStep from './PermissionStep';
import ReviewStep from './ReviewStep';
import SpecStep from './SpecStep';
import validationSchema from './validationSchema';
import { useNavigate } from 'react-router';

const steps = [
    {
        label: 'Basic',
        description: 'Basic information about the node'
    },
    {
        label: 'Location & Timezone',
        description: 'Provide your physical location for better monitoring and enable Geo Location based services'
    },
    {
        label: 'Spec Info',
        description: 'Tell us about your Nodes spec'
    },
    {
        label: 'Permission (Optional)',
        description: 'Permissions related to your Node'
    },
    {
        label: 'Review',
        description: 'Review your information'
    }
];

export default function NodeCreatePage() {
    const [activeStep, setActiveStep] = useState(0);
    const navigate = useNavigate();
    const { user, reloadAuth } = useAuth();
    const isLastStep = activeStep === steps.length - 1;
    const isReviewStep = activeStep === steps.length - 2;
    const isFirstStep = activeStep === 0;
    const isCreationStep = activeStep === steps.length;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [result, setResult] = useState<NodeInfo | null>(null);
    const [error, setError] = useState<string | null>(null);
    const { t } = useTranslation();

    const initialValues: NodeCreationForm = {
        name: '',
        version: '0.1',
        description: '',
        owner: user!,
        config: {
            timezone: 'Asia/Shanghai',
            cache_folder: './temp'
        },
        spec: {
            os: 'Windows 10',
            cpu: ''
        }
    };
    const currentValidationSchema = validationSchema(t)[activeStep];

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleCreate = async (values: NodeCreationForm) => {
        setError(null);
        setIsSubmitting(true);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        try {
            const nodeRequest = {
                ...values,
                owner: values.owner.id
            };
            const node = await createNode(nodeRequest as any);
            await reloadAuth();
            setResult(node);
        } catch (error: any) {
            setError(t('Failed to create node') + ' : ' + JSON.stringify(error?.response?.data?.message));
        } finally {
            setIsSubmitting(false);
        }
    };
    const handleSubmit = (values: NodeCreationForm, actions: any) => {
        if (isLastStep) {
            handleCreate(values);
        } else {
            if (!isReviewStep) {
                actions.resetForm({ values });
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    };

    return (
        <MainCard title={t('Node Creation Wizard')} contentSX={{ px: 8 }}>
            <Formik initialValues={initialValues} validationSchema={currentValidationSchema} onSubmit={handleSubmit}>
                {({ values, isValid, dirty }) => (
                    <Form>
                        <Grid container spacing={5}>
                            <Grid item xs={3}>
                                <NodeCreationStepper
                                    steps={steps}
                                    activeStep={activeStep}
                                    setActiveStep={setActiveStep}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                {activeStep === 0 && <BasicStep />}
                                {activeStep === 1 && <LocationStep />}
                                {activeStep === 2 && <SpecStep />}
                                {activeStep === 3 && <PermissionStep />}
                                {activeStep === 4 && <ReviewStep values={values} />}
                                {isCreationStep && (
                                    <ResultStep isCreating={isSubmitting} error={error} result={result} />
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" gap={2} justifyContent="flex-end">
                                    {!isFirstStep && result === undefined && (
                                        <Button variant="contained" onClick={handleBack}>
                                            {t('Back')}
                                        </Button>
                                    )}
                                    {!isCreationStep && (
                                        <Button variant="contained" type="submit" disabled={!isValid}>
                                            {isLastStep ? t('Create') : t('Next')}
                                        </Button>
                                    )}
                                    {isCreationStep && result && (
                                        <Button variant="contained" onClick={() => navigate(`/nodes/${result.id}`)}>
                                            {t('Done')}
                                        </Button>
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </MainCard>
    );
}
