import { forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';

// constant
const headerSX = {
    '& .MuiCardHeader-action': { mr: 0 }
};
type MainCardProps = {
    border: boolean;
    boxShadow: boolean;
    children: React.ReactNode;
    content: boolean;
    contentClass: string;
    contentSX: object;
    darkTitle: boolean;
    secondary: string | React.ReactNode;
    hoverShadow: string;
    sx: object;
    title: string | React.ReactNode;
    [otherOptions: string]: unknown;
};
// ==============================|| CUSTOM MAIN CARD ||============================== //
const MainCard = forwardRef(
    (
        {
            border = false,
            boxShadow = true,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            secondary,
            hoverShadow = '2',
            sx = {},
            title,
            ...others
        }: MainCardProps,
        ref: React.Ref<any>
    ) => {
        const theme = useTheme();

        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    borderColor: theme.palette.common.black,
                    boxShadow: boxShadow ? '1' : 'inherit',
                    ':hover': {
                        boxShadow: boxShadow ? hoverShadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
                    },
                    ...sx
                }}
            >
                {/* card header and action */}
                {!darkTitle && title && <CardHeader sx={headerSX} title={title} action={secondary} />}
                {darkTitle && title && (
                    <CardHeader
                        sx={headerSX}
                        title={<Typography variant="h3">{title}</Typography>}
                        action={secondary}
                    />
                )}

                {/* content & header divider */}
                {title && <Divider />}

                {/* card content */}
                {content && (
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

export default MainCard;
