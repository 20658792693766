import { Grid } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';

export default function BasicStep() {
    const { t } = useTranslation();
    return (
        <Grid container spacing={4}>
            <Grid item xs={4}>
                <Field
                    component={TextField}
                    type="text"
                    name="name"
                    label={t('node_name')}
                    helperText={t('Human readable node name Like Beijing Basic Node')}
                />
            </Grid>
            <Grid item xs={4}>
                <Field
                    component={TextField}
                    type="text"
                    name="config.cache_folder"
                    label={t('Cache folder')}
                    helperText={t('make sure it exists')}
                />
            </Grid>
            <Grid item xs={3}>
                <Field
                    component={TextField}
                    type="text"
                    disabled
                    name="version"
                    label={t('Node Version')}
                    helperText={t('Target software version')}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    component={TextField}
                    type="text"
                    name="description"
                    label={t('Node Description')}
                    fullWidth
                    multiline
                    rows={4}
                    maxRows={10}
                    helperText={t('Write something about your node')}
                />
            </Grid>
        </Grid>
    );
}
