import { Grid } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';

export default function PermissionStep() {
    const { t } = useTranslation();
    return (
        <Grid container spacing={2} rowSpacing={4}>
            <Grid item xs={3}>
                <Field
                    component={TextField}
                    type="text"
                    disabled
                    name="owner.username"
                    label={t('Owner')}
                    helperText={t('Current user will be the owner of the Node')}
                />
            </Grid>
            <Grid item xs={9} />
        </Grid>
    );
}
