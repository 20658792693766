import axios from '../http';
import { User } from '../interfaces/auth.interface';

interface LoginResponse {
    user_info: User;
    jwt_token: string;
}

export const login = async (username: string, password: string) => {
    const loginResponse = await axios.post<LoginResponse>('/auth/login', { username, password });
    return loginResponse.data;
};

export const getCurrentUser = async () => {
    const response = await axios.get<User>('/auth/current');
    return response.data;
};
