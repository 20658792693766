import React, { createContext, useContext, useEffect } from 'react';
import SnackbarUtils from 'utils/SnackbarUtils';
import { User } from '../interfaces/auth.interface';
import { getCurrentUser } from './authService';

interface IAuthContext {
    user: User | null;
    setUser: (user: User | null) => void;
    token: string | null;
    setToken: (token: string | null) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    reloadAuth: () => Promise<void>;
}

export const AUTH_TOKEN_KEY = 'auth-token';
export const AUTH_USER_KEY = 'auth-user';

export const AuthContext = createContext<IAuthContext>({
    user: null,
    setUser: () => {},
    token: null,
    setToken: () => {},
    loading: false,
    setLoading: () => {},
    reloadAuth: () => Promise.resolve()
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = React.useState<User | null>(
        localStorage.getItem(AUTH_USER_KEY) ? JSON.parse(localStorage.getItem(AUTH_USER_KEY) as string) : null
    );
    const [token, setToken] = React.useState<string | null>(localStorage.getItem(AUTH_TOKEN_KEY));
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleUserUpdate = (user: User | null) => {
        if (user) {
            localStorage.setItem(AUTH_USER_KEY, JSON.stringify(user));
        } else {
            localStorage.removeItem(AUTH_USER_KEY);
        }
        setUser(user);
    };

    const handleTokenUpdate = (token: string | null) => {
        if (token) {
            localStorage.setItem(AUTH_TOKEN_KEY, token);
        } else {
            localStorage.removeItem(AUTH_TOKEN_KEY);
        }
        setToken(token);
    };

    const reloadAuth = async () => {
        try {
            setLoading(true);
            const user = await getCurrentUser();
            handleUserUpdate(user);
        } catch (e: any) {
            SnackbarUtils.error("Can't load user data. " + e.message);
            handleUserUpdate(null);
            throw e;
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (token && !window.location.pathname.includes('/login')) {
            reloadAuth();
        }
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user,
                setUser: handleUserUpdate,
                token,
                setToken: handleTokenUpdate,
                loading,
                setLoading,
                reloadAuth
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
