import {
    NodeInfo,
    NodeToken,
    CameraMap,
    Camera,
    IDictonary,
    DetectedCamera,
    TakeSampleRequest,
    PaginationQuery,
    NodeEventListPage
} from 'interfaces/api.interface';
import axios from '../http';

export const getNodeList = async () => {
    const response = await axios.get<NodeInfo[]>('/nodes');
    return response.data;
};

export const createNode = async (node: NodeInfo) => {
    const response = await axios.post<NodeInfo>('/nodes', node);
    return response.data;
};

export const getNode = async (nodeId: string) => {
    const response = await axios.get<NodeInfo>(`/nodes/${nodeId}`);
    return response.data;
};

export const deleteNode = async (nodeId: string) => {
    await axios.delete<null>(`/nodes/${nodeId}`);
};

export const getNodeToken = async (nodeId: string) => {
    const response = await axios.get<NodeToken>(`/nodes/${nodeId}/token`);
    return response.data;
};

export const getNodeConfig = async (nodeId: string) => {
    const response = await axios.get<Object>(`/nodes/${nodeId}/config`);
    return response.data;
};

export const updateNodeConfig = async (nodeId: string, config: any) => {
    const response = await axios.put<null>(`/nodes/${nodeId}/config`, config);
    return response.data;
};

export const refreshNodeToken = async (nodeId: string) => {
    const response = await axios.post<NodeToken>(`/nodes/${nodeId}/token/refresh`);
    return response.data;
};

export const sendNodeActionRaw = async (nodeId: string, payload: IDictonary) => {
    const response = await axios.post<null>(`/nodes/${nodeId}/action`, payload);
    return response.data;
};

export const sendNodeAction = async (nodeId: string, action: string, data: IDictonary | undefined = undefined) => {
    const response = await axios.post<null>(`/nodes/${nodeId}/action`, {
        command: action,
        data: data
    });
    return response.data;
};

// Node Utils
export const callNodeUtil = async (nodeId: string, type: string, data: IDictonary | undefined = undefined) => {
    const response = await axios.post<null>(`/nodes/${nodeId}/utils/${type}`, data);
    return response.data;
};

export const queryCameras = async (nodeId: string) => {
    const response = await axios.post<DetectedCamera[]>(`/nodes/${nodeId}/utils/query_cameras`, {});
    return response.data;
};

export const takeSample = async (nodeId: string, data: TakeSampleRequest) => {
    const response = await axios.post<any>(`/nodes/${nodeId}/utils/take_sample`, data);
    return response.data;
};

export const measureCamera = async (nodeId: string, data: TakeSampleRequest) => {
    const response = await axios.post<any>(`/nodes/${nodeId}/utils/measure_camera`, data);
    return response.data;
};

export const calibrateCamera = async (nodeId: string, data: TakeSampleRequest) => {
    const response = await axios.post<any>(`/nodes/${nodeId}/utils/calibrate_camera`, data);
    return response.data;
};

// Camera related API

export const getCameraMap = async (nodeId: string) => {
    const response = await axios.get<CameraMap>(`/nodes/${nodeId}/cameras`);
    return response.data;
};

export const getCamera = async (nodeId: string, cameraId: string) => {
    const response = await axios.get<Camera>(`/nodes/${nodeId}/cameras/${cameraId}`);
    return response.data;
};

export const postCamera = async (nodeId: string, camera: Camera) => {
    const response = await axios.post<CameraMap>(`/nodes/${nodeId}/cameras`, camera);
    return response.data;
};

export const putCamera = async (nodeId: string, cameraId: string, camera: Camera) => {
    const response = await axios.put<Camera>(`/nodes/${nodeId}/cameras/${cameraId}`, camera);
    return response.data;
};

export const deleteCamera = async (nodeId: string, cameraId: string) => {
    await axios.delete(`/nodes/${nodeId}/cameras/${cameraId}`);
};

export const queryNodeEvents = async (query: {
    nodeId: string;
    type?: string;
    level?: string;
    sort_by?: string;
    pagination: PaginationQuery;
    filters: { [key: string]: any };
}) => {
    const params = {
        type: query.type,
        level: query.level,
        sort_by: query.sort_by,
        ...query.pagination,
        ...query.filters
    };
    const response = await axios.get<NodeEventListPage>(`/nodes/${query.nodeId}/events`, { params });
    return response.data;
};
