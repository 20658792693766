// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = (t) => {
    return {
        id: 'dashboard',
        title: t('dashboard'),
        type: 'group',
        children: [
            {
                id: 'default',
                title: t('dashboard-discription'),
                type: 'item',
                url: '/dashboard/default',
                icon: icons.IconDashboard,
                breadcrumbs: true
            }
        ]
    };
};

export default dashboard;
