// assets
import { IconUsers, IconFileDescription } from '@tabler/icons';

// constant
const icons = { IconUsers, IconFileDescription };

const other = (t) => {
    return {
        id: 'other',
        type: 'group',
        children: [
            {
                id: 'about-us',
                title: t('About Us'),
                type: 'item',
                url: '/about-us',
                icon: icons.IconUsers,
                breadcrumbs: true
            },
            {
                id: 'documentation',
                title: t('Documentation'),
                type: 'item',
                url: 'https://blog.meteoroid.fit/',
                icon: icons.IconFileDescription,
                external: true,
                target: true
            }
        ]
    };
};

export default other;
