// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const utilities = (t) => {
    return {
        id: 'utilities',
        title: 'Utilities',
        type: 'group',
        debug: true,
        resource: 'user',
        display_for: ['admin'],
        children: [
            {
                id: 'util-typography',
                title: 'Typography',
                type: 'item',
                url: '/utils/util-typography',
                icon: icons.IconTypography,
                breadcrumbs: true
            },
            {
                id: 'util-color',
                title: 'Color',
                type: 'item',
                url: '/utils/util-color',
                icon: icons.IconPalette,
                breadcrumbs: true
            },
            {
                id: 'util-shadow',
                title: 'Shadow',
                type: 'item',
                url: '/utils/util-shadow',
                icon: icons.IconShadow,
                breadcrumbs: true
            },
            {
                id: 'icons',
                title: 'Icons',
                type: 'collapse',
                icon: icons.IconWindmill,
                children: [
                    {
                        id: 'tabler-icons',
                        title: 'Tabler Icons',
                        type: 'item',
                        url: '/icons/tabler-icons',
                        breadcrumbs: true
                    },
                    {
                        id: 'material-icons',
                        title: 'Material Icons',
                        type: 'item',
                        url: '/icons/material-icons',
                        breadcrumbs: true
                    }
                ]
            }
        ]
    };
};

export default utilities;
