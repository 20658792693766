// material-ui
// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useAppState } from 'contexts/AppContext';
import { useAuth } from 'auth/AuthContext';
import { useTranslation } from 'react-i18next';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { user } = useAuth();
    const { t } = useTranslation();
    const {
        state: { isDebug }
    } = useAppState();
    const filteredMenuItem = menuItem.items.map((item) => item(t)).filter((item) => (item.debug ? isDebug : true));
    const navItems = filteredMenuItem.map((item) => {
        if (!item.resource) return <NavGroup key={item.id} item={item} />;
        const hasPermission = item.display_for.some((role) =>
            user.resource_permissions.some(
                (resource_permission) =>
                    resource_permission.resource === item.resource && resource_permission.role === role
            )
        );
        if (!hasPermission) return null;

        return <NavGroup key={item.id} item={item} />;
    });

    return <>{navItems}</>;
};

export default MenuList;
